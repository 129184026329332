import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/view/pages/Guest")
    },
    {
      path: "/terms",
      component: () => import("@/view/pages/Terms")
    },
    {
      path: "/pricing",
      component: () => import("@/view/pages/Price")
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/docs",
          name: "Docs",
          component: () => import("@/view/pages/Docs.vue")
        },
        {
          path: "/billing",
          name: "billing",
          component: () => import("@/view/pages/Billing")
        },
      ]
    },
    {
      path: "/stats",
      redirect: "/stats/overview",
      component: () => import("@/view/layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/stats/overview",
          name: "Overview",
          component: () => import("@/view/pages/stats/Overview.vue")
        },
        {
          path: "/stats/daily",
          name: "Docs",
          component: () => import("@/view/pages/stats/Daily.vue")
        },
        {
          path: "/stats/campaign",
          name: "stats",
          component: () => import("@/view/pages/stats/Campaign.vue")
        },
        {
          path: "/stats/campaign/:id",
          name: "stats",
          component: () => import("@/view/pages/stats/Campaign.vue")
        },
        {
          path: "/stats/cloaker",
          name: "stats",
          component: () => import("@/view/pages/stats/Cloaker.vue")
        },
        {
          path: "/stats/location",
          name: "stats",
          component: () => import("@/view/pages/stats/Location.vue")
        }

      ]
    },
    {
      path: "/",
      redirect: "/build",
      component: () => import("@/view/layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/build",
          redirect: "/build/new",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "/build/new",
              name: "new-campaign",
              component: () => import("@/view/pages/wizard/Campaign.vue")
            },
            {
              path: "/build/:id",
              name: "edit-campaign",
              component: () => import("@/view/pages/wizard/Campaign.vue")
            }
          ]
        }
        // {
        //   path: "/build/profile",
        //   name: "profile",
        //   component: () => import("@/view/pages/profile/Profile.vue"),
        //   children: [
        //     {
        //       path: "profile",
        //       name: "profile",
        //       component: () => import("@/view/pages/profile/Profile-1.vue")
        //     }
        //   ]
        // }
      ]
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/view/pages/Login")
    },
    {
      name: "signup",
      path: "/signup",
      component: () => import("@/view/pages/SignUp")
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});
