import ApiService from "@/core/services/api.service";

// action types
export const GET_CAMPAIGNS = "getCampaigns";
// mutation types
export const SET_CAMPAIGNS = "setCampaigns";
export const FILTER_CAMPAIGNS = "filterCampaigns";
export const SET_ERROR = "setError";

const state = {
  list: []
};

const actions = {
  [GET_CAMPAIGNS](context, data) {
    const { newRequest, query, params } = data;
  
    if (!newRequest) {
      context.commit(FILTER_CAMPAIGNS, query);
      return;
    }

    return new Promise(resolve => {
      ApiService.query("campaign/list", { params })
        .then(({ data }) => {
          const campaigns = data.campaigns.map(it => ({ ...it, hide: !((it.name || '').toLowerCase()).includes(query.toLowerCase()) }));

          context.commit(SET_CAMPAIGNS, campaigns);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CAMPAIGNS](state, campaigns) {
    const assoc = { 'block_all': 0, 'active': 1, 'allow_all': 2 };
  
    state.list = campaigns.map((campaign) => ({
      ...campaign,
      statusAsNumber: assoc[campaign.status]
    }));
  },
  [FILTER_CAMPAIGNS](state, query) {
    state.list = state.list.map(it => ({ ...it, hide: !((it.name || '').toLowerCase()).includes(query.toLowerCase()) }));
  }
};

export default {
  state,
  actions,
  mutations
};
